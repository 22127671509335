<template>
  <div @click="onClick" class="z-card" v-if="show">
    <div class="card-content">
      <div
        class="image"
        :style="{
          'background-image': `url(${
            imageUrl || require('/public/coursePlaceholder.png')
          })`,
        }"
      ></div>
      <div class="text-wrapper">
        <div class="badge-wrapper mb-2">
          <PathBadge v-if="isPath" class="flex-shrink-0" />
          <HandbookBadge v-if="isManual" class="flex-shrink-0" />
          <Subtitles
            v-if="showLanguages"
            :languages="languages"
            :popupEnabled="false"
          />
        </div>
        <h3 class="z-fw-6" v-if="title">
          <router-link :to="url" class="link" @click.prevent>
            {{ title }} <span v-if="disabled"> ({{ $t("WIP") }})</span>
          </router-link>
        </h3>
        <div class="tag-wrapper" :v-if="tags" v-for="(tag, i) in tags" :key="i">
          <p class="z-fw-6">{{ tag }}</p>
        </div>
        <p
          class="description z-fw-1"
          v-if="description && description.length"
          v-html="$options.filters.truncate(description, 400, '...')"
        ></p>
      </div>
    </div>

    <div class="footer">
      <slot name="footer-fluid"></slot>

      <div class="d-flex justify-content-between footer-sides">
        <div>
          <slot name="footer-left"></slot>
        </div>
        <div>
          <slot name="footer-right"></slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { resolveCmsMediaUrl } from "/utils/helpers";

export default {
  components: {
    PathBadge: () => import("/components/Path/PathBadge.vue"),
    HandbookBadge: () => import("/components/HandbookBadge.vue"),
    Subtitles: () => import("/components/Subtitles/Subtitles.vue"),
  },
  props: {
    image: {
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      required: false,
    },
    tags: {
      type: Array,
      required: false,
    },
    url: {
      type: String,
      required: true,
    },
    disabled: {
      type: Boolean,
      required: false,
    },
    show: {
      type: Boolean,
      required: false,
      default: true,
    },
    isLocked: {
      type: Boolean,
      required: false,
      default: false,
    },
    languages: {
      type: Array,
      required: false,
    },
    isPath: {
      type: Boolean,
      required: false,
      default: false,
    },
    isManual: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    ...mapGetters("auth", ["isLoggedIn"]),
  },
  data() {
    return {
      imageUrl: resolveCmsMediaUrl(this.image),
      showLanguages: this.languages ? this.languages.length !== 0 : false,
    };
  },
  methods: {
    onClick() {
      if (!this.isLoggedIn) {
        localStorage.setItem("LAST_FROM_ROUTE", this.url);
      }

      if (this.isLocked) {
        this.EventBus.$emit("locked-content-accessed");
        return;
      }
      this.$router.push(this.disabled ? `#${this.url}` : this.url);
    },
  },
};
</script>

<style lang="scss" scoped>
.link {
  font: inherit;
}
.link::after {
  content: "";
  position: absolute;
  inset: 0;
}

a {
  color: #000000;
}

a:hover {
  text-decoration: none;
}

.z-card {
  width: 22.125rem;
  transition-duration: 200ms;
  margin-bottom: 2.5rem !important;

  border-radius: 0 0 0.625rem 0.625rem;
  border: 1px solid rgba(71, 29, 193, 0.05);

  display: flex;
  flex-wrap: wrap;
  overflow: hidden;

  position: relative;

  .footer {
    padding: 0.9375rem 1.25rem 1.25rem;
    align-self: flex-end;
    width: 100%;
  }

  .footer-sides {
    padding-top: 0.625rem;
  }
}

.z-card:hover {
  box-shadow: 0 0 3.125rem rgba(0, 0, 0, 0.07);
  cursor: pointer;
}

.z-card.disabled {
  opacity: 0.5;
}

.card-content {
  width: 100%;

  .image {
    width: 100%;
    padding-top: 56.25%;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
  }

  .text-wrapper {
    padding: 2.3125rem 1.1875rem 0.9375rem 2.1875rem;

    h3 {
      font-size: 1.375rem;
      margin-bottom: 0.9375rem;
    }

    .badge-wrapper {
      display: flex;
      gap: 0.5rem;
      height: 2.0625rem;
    }

    .tag-wrapper {
      display: inline-block;
      padding: 0.625rem 1.125rem;
      background-color: #f0fafd;
      border-radius: 2.5rem;
      margin: 0 0.5rem 0.75rem 0;

      p {
        font-size: 1rem;
        line-height: 1rem;
        margin: 0;
        color: #42c9f8;
      }
    }

    .description {
      font-size: 1rem;
      line-height: 1.5625rem;
      color: #031d5b;

      margin-top: 1.125rem;
    }
  }
}
</style>
